document.addEventListener("DOMContentLoaded", function() {
    const partnerForm = document.getElementById('partnerForm');
    if (partnerForm) {
        partnerForm.addEventListener('submit', function(event) {
            sessionStorage.setItem('formSubmitted', 'true');
        });

        const urlParams = new URLSearchParams(window.location.search);
        const successStatus = urlParams.get('success');
        const formSubmitted = sessionStorage.getItem('formSubmitted');
        urlParams.delete('success');
        const newUrl = window.location.pathname;
        window.history.replaceState(null, '', newUrl);
        sessionStorage.setItem('formSubmitted', 'false');

        if (successStatus === 'true' && formSubmitted === 'true') {
            const confirmation = document.getElementById('confirmation');
            if (confirmation) {
                confirmation.classList.toggle('active');
                document.body.style.overflow='hidden';
                const confirmationError = document.getElementById('confirmationError');
                const confirmationSuccess = document.getElementById('confirmationSuccess');

                if (confirmationError && confirmationSuccess) {
                    confirmationError.style.display = 'none';
                    confirmationSuccess.style.display = 'flex';
                }
            }
        }
    }
});

const contactButton = document.querySelector('.flex-component__form--contact-button');
if (contactButton) {
    contactButton.addEventListener('click', function () {
        this.classList.toggle('active');
        const contactForm = document.getElementById('contactForm');
        if (contactForm) {
            contactForm.classList.toggle('active');
        }
    });
}

const contactForm = document.getElementById('contactForm');
if (contactForm) {
    contactForm.addEventListener('submit', async function (event) {
        event.preventDefault();

        const formData = new FormData(this);

        const firstName = formData.get('first_name');
        const lastName = formData.get('last_name');
        const email = formData.get('email');
        const description = formData.get('description');
        const phone = formData.get('phone');
        const topic = formData.get('help_topic');
        const userPreference = formData.get('userPreference');

        let isValid = true;
        clearErrorMessages();

        isValid = isValidInput(firstName, 'firstNameError', 'First name is required', isValid);
        isValid = isValidInput(lastName, 'lastNameError', 'Last name is required', isValid);
        isValid = isValidInput(phone, 'phoneError', 'Phone number is required', isValid);
        isValid = isValidInput(topic, 'helpTopicError', 'Please select a help topic', isValid);
        isValid = isValidInput(description, 'commentsError', 'Comments are required', isValid);

        if (!email || !isValidEmail(email)) {
            showMessage('emailError', 'Please enter a valid email address');
            isValid = false;
        }

        if (!isValid) {
            return;
        }

        const submitButton = this.querySelector('button[type="submit"]');
        if (submitButton) {
            submitButton.disabled = true;
            submitButton.textContent = 'Submitting...';
        }

        try {
            const response = await fetch('/actions/contactform/contact-form/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    email,
                    phone,
                    helpTopic: topic,
                    comments: description,
                    userPreference,
                }),
            });

            this.reset();

            if (!response.ok) {
                showOverlay(document, false);
                return;
            }

            const result = await response.json();

            if (result.success) {
                showOverlay(document, true);
            } else {
                showOverlay(document, false);
            }
        } catch {
            this.reset();
            showOverlay(document, false);
        } finally {
            if (submitButton) {
                submitButton.disabled = false;
                submitButton.textContent = 'Submit';
            }
        }
    });
}

const formConfirmationClose = document.getElementById('formConfirmationClose');
if (formConfirmationClose) {
    formConfirmationClose.addEventListener('click', function() {
        const confirmation = document.getElementById('confirmation');
        if (confirmation) {
            confirmation.classList.toggle("active");
            document.body.style.overflow = '';
        }
    });
}

function isValidInput(input, errorInput, errorMessage, isValid) {
    if (!input || input.trim() === '') {
        showMessage(errorInput, errorMessage);
        return false;
    }
    return isValid;
}

function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
}

function showMessage(elementId, message) {
    const messageElement = document.getElementById(elementId);
    if (messageElement) {
        messageElement.textContent = message;
    }
}

function clearErrorMessages() {
    document.querySelectorAll('.flex-component__form--element--error').forEach(el => el.textContent = '');
}

function showOverlay(document, success) {
    const confirmation = document.getElementById('confirmation');
    if (confirmation) {
        confirmation.classList.toggle('active');
        document.body.style.overflow = 'hidden';
        const confirmationError = document.getElementById('confirmationError');
        const confirmationSuccess = document.getElementById('confirmationSuccess');
        if (confirmationError && confirmationSuccess) {
            if (success) {
                confirmationError.style.display = 'none';
                confirmationSuccess.style.display = 'flex';
            } else {
                const formConfirmationCheck = document.getElementById('formConfirmationCheck');
                if (formConfirmationCheck) {
                    formConfirmationCheck.style.display = 'none';
                }
                const confirmationHeader = document.querySelector('.flex-component__form--confirmation--content--header');
                if (confirmationHeader) {
                    confirmationHeader.classList.add('error');
                }
                confirmationError.style.display = 'flex';
                confirmationSuccess.style.display = 'none';
            }
        }
    }
}
